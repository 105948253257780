// App.js - WEB
import React, { Component } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import HomeScreen from "../../../../components/src/HomeScreen";
/** WebRoutes file **/
// @ts-ignore
import WebRoutes from "./webRoutes";
import ScrollToTopButton from "react-scroll-to-top";
import { ToastContainer } from "react-toastify";
// @ts-ignore
import { onMessageListener, getTokenData } from './firebaseInit.js';

//css
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "./App.css";

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: "HomeScreen"
  };
  const homeScreen = new HomeScreen(defaultProps);
}


class App extends Component {
  async componentDidMount() {

    Notification.requestPermission().then(function (permission) {  });


    getTokenData()


  }

  render() {

    onMessageListener().then((payload: any) => {
    }).catch((err: any) => {});
    return (
      <>
        <WebRoutes />
        <ScrollToTopButton smooth />
        <ToastContainer />
      </>
    );
  }
}

export default App;
