// @ts-nocheck
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { RouteComponentProps } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Starts
  myCategory?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeCategoryId: String;
  filterParams?: any;
  more: boolean;
  activeIndex: any;
  buttonsToAllow: number;
  shouldAddMore: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class FilterOptionListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCategoryAPICallId: string;
  wrapperRef: DetailedHTMLProps<HTMLAttributes>;
  buttonRef: DetailedHTMLProps<HTMLAttributes>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    let urlTypes = null;
    if (typeof window !== "undefined") {
      urlTypes = window?.location?.search;
    }
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      activeCategoryId: "",
      filterParams: new URLSearchParams(urlTypes),
      more: false,
      activeIndex: 0,
      buttonsToAllow: 0,
      shouldAddMore: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.wrapperRef = React.createRef();
    this.buttonRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // Customizable Area End
  }

  filterDisplay = () => {
    const containersWidth = this.wrapperRef?.current?.clientWidth;
    const buttons = document.querySelectorAll(".category-pills-btn");
    let shouldAddMore = false;
    let buttonsToAllow = 0;
    let totalWidth = 0;
    for (let i = 0; i < buttons.length; i++) {
      totalWidth += buttons[i].clientWidth + 40;
      if (totalWidth < containersWidth) {
        buttonsToAllow++;
      }
    }
    if (buttons.length > buttonsToAllow) {
      if (buttonsToAllow > 1) {
        buttonsToAllow -= 1;
      }
      shouldAddMore = true;
    }
    this.setState({
      buttonsToAllow: buttonsToAllow,
      shouldAddMore: shouldAddMore,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    this.getActiveCat();
    document.addEventListener("mousedown", this.handleClickOutside);
    this.filterDisplay();
    window.addEventListener("resize", this.filterDisplay);
    // Customizable Area End
  }


  componentWillUnmount() {
    super.componentWillUnmount();
    // Customizable Area Start
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.filterDisplay();
    window.removeEventListener("resize", this.filterDisplay);
    // Customizable Area End
  }

  componentWillReceiveProps(prevState: any) {
    // Customizable Area Start
    this.getActiveCat();
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponseMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponseDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponseSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponseErrorMessage)
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleClickOutside(event) {
    if (
      this.state.more &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ more: false });
    }
  }

  //mark category active & sort product
  getActiveCat = () => {
    let urlTypes: any;
    if (typeof window !== "undefined") {
      urlTypes = window?.location?.search;
    }
    let urlSearch = new URLSearchParams(urlTypes);
    if (urlSearch.get("category_id") !== null) {
      this.setState({
        activeCategoryId: urlSearch.get("category_id"),
        more: false,
      });
    } else {
      this.setState({ activeCategoryId: "" });
    }
  };

  //route to filtered category
  routeToFilter = (value: any, index: number) => {
    this.setState({ activeIndex: index });
    let urlTypes = null;
    if (typeof window !== "undefined") {
      urlTypes = window?.location?.search;
    }
    let urlSearch = new URLSearchParams(urlTypes);
    urlSearch.delete("q[name]");
    urlSearch.delete("favourite");
    urlSearch.set("category_id", value);
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
  };

  // get category list

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
