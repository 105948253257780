import React, { Fragment } from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";

import EmptyCart from "./EmptyCartContent.web";
import { BsX } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  Button,
  Table,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { FaCopy } from "react-icons/fa";
import {
  FoodTagVeg,
  FoodTagNonVeg,
  modalCloseIcon,
  rewardIcon,
} from "./assets";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import content from "../../studio-store-restaurant-theme/src/AppWebContent";
import { AiFillStar } from "react-icons/ai";
import AdvBannerSkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/AdvBannerSkeltonLoader";

import "../assets/css/shoppingCart.css";
import "../assets/css/removeCartProduct.css";
// Customizable Area End

import ShoppingCartController, { Props } from "./ShoppingCartController.web";
import {
  errorNotification,
  getLocalState,
  showTwoDecimalDigit,
  warnNotification,
} from "../../studio-store-restaurant-components/src/Utility.web";
import ConfirmActionModel from "../../studio-store-restaurant-components/src/common/ConfirmModel";

class ShoppingCart extends ShoppingCartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    return (
      <>
        {this.props?.myCartList?.order &&
        this.props?.myCartList?.order?.data?.attributes?.order_items &&
        this.props?.myCartList?.order?.data?.attributes?.order_items.length >
          0 ? (
          <div>
            <div className="bg-white yt-cart-price-lister">
              <div className="my-order-cart-title">{content.yourCart}</div>
              <span className="homepage-cart-divider mb-4" />
              {this.props?.myCartList?.order?.data?.attributes?.order_items.map(
                (inCart: any, index: any) => (
                  <div className="row" key={index}>
                    <div className="col">
                      <div className="cart-prodict-info">
                        <h2 className="cart-product-title mt-2 text-capitalize">
                          {inCart?.attributes?.catalogue?.attributes?.name?.toLowerCase()}
                        </h2>
                        <p
                          key={index}
                          className="food-subtitle"
                          style={{ color: "#757575" }}
                        >
                          <span>
                            {" "}
                            {this.onHandleCustomizeItems(
                              inCart?.attributes?.order_item_options
                            )}{" "}
                          </span>
                        </p>
                      </div>
                      <div className="d-flex">
                        <div className="veg-nonVeg">
                          <img
                            src={
                              inCart?.attributes?.catalogue?.attributes?.non_veg
                                ? FoodTagNonVeg
                                : FoodTagVeg
                            }
                            className="img-fluid"
                          />
                          {inCart?.attributes?.catalogue?.attributes
                            ?.non_veg ? (
                            <span>&nbsp;&nbsp;Non-vegetarian</span>
                          ) : (
                            <span>&nbsp;&nbsp;Vegetarian</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <span className="cart-product-amount-qty d-flex justify-content-end align-items-center">
                        <FaRegTrashAlt
                          data-testid="rmItemSvgID"
                          className="remove-item-svg"
                          onClick={() => {
                            this.setState({
                              isCartItemRemove: true,
                              cartItemId: inCart?.id,
                            });
                          }}
                        />
                        <div className="qty-input">
                          <button
                            data-testid="subtractCartQuantityID"
                            className="qty-count qty-count--minus"
                            data-action="minus"
                            type="button"
                            onClick={() => {
                              if (
                                inCart?.attributes?.catalogue?.attributes
                                  ?.cart_quantity +
                                  this.state.itemQuantity -
                                  1 <
                                1
                              ) {
                                warnNotification(
                                  `You can not set less than 1 quantity`
                                );
                              } else {
                                this.updateCartQuantity(inCart, "Subtract");
                              }
                            }}
                          >
                            -
                          </button>
                          <input
                            className="product-qty"
                            type="number"
                            name="product-qty"
                            min="0"
                            max="10"
                            value={inCart?.attributes?.quantity}
                          />
                          <button
                            data-testid="addCartQuantityID"
                            className="qty-count qty-count--add"
                            data-action="add"
                            type="button"
                            onClick={() => {
                              let cartLimit =
                                inCart?.attributes?.catalogue?.attributes
                                  ?.cart_limit;
                              if (
                                inCart?.attributes?.catalogue?.attributes
                                  ?.cart_quantity +
                                  this.state.itemQuantity +
                                  1 >
                                  cartLimit &&
                                cartLimit
                              ) {
                                warnNotification(
                                  `You can not add more than ${cartLimit} quantity of this product`
                                );
                              } else {
                                this.updateCartQuantity(inCart, "Add");
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                      </span>
                    </div>
                    <div className="col text-end">
                      <div className="product-cart-amount">
                        {/* @ts-ignore */}
                        <span
                          style={{ marginTop: 4 }}
                          className="cart-product-amount-price"
                        >
                          {showTwoDecimalDigit(inCart?.attributes?.total_price)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}

              <span className="homepage-cart-divider mt-3" />
              <Table className="mb-0 cart-prodict-total-amount " borderless>
                <tbody>
                  <>
                    {parseFloat(
                      this.props?.myCartList?.order?.data?.attributes?.sub_total
                    ) > 0 && (
                      <tr>
                        <td>
                          <span
                            className="cart-product-amount-ttl"
                            style={{ fontWeight: "bold" }}
                          >
                            {content.subTotal}
                          </span>
                        </td>
                        <td>
                          <span
                            className="cart-product-amount-price"
                            style={{ fontWeight: "bold" }}
                          >
                            {/* @ts-ignore */}
                            {showTwoDecimalDigit(
                              this.props?.myCartList?.order?.data?.attributes
                                ?.sub_total
                            )}
                          </span>
                        </td>
                      </tr>
                    )}
                    {/* @ts-ignore */}
                    {this.props?.myCartList?.order?.data?.attributes
                      ?.total_tax > 0 && (
                      <tr>
                        <td>
                          <span
                            className="cart-product-amount-ttl"
                            style={{ fontSize: "14px" }}
                          >
                            {content.taxes}
                          </span>
                        </td>
                        <td>
                          <span
                            className="cart-product-amount-price"
                            style={{ fontSize: "14px" }}
                          >
                            +
                            {showTwoDecimalDigit(
                              this.props?.myCartList?.order?.data?.attributes
                                ?.total_tax
                            )}
                          </span>
                        </td>
                      </tr>
                    )}
                    {parseFloat(
                      this.props?.myCartList?.order?.data?.attributes
                        ?.shipping_charge
                    ) > 0 && (
                      <tr>
                        <td>
                          <span
                            className="cart-product-amount-ttl"
                            style={{ fontSize: "14px" }}
                          >
                            {content.deliveryCharges}
                          </span>
                        </td>
                        <td>
                          <span
                            className="cart-product-amount-price"
                            style={{ fontSize: "14px" }}
                          >
                            +
                            {showTwoDecimalDigit(
                              this.props?.myCartList?.order?.data?.attributes
                                ?.shipping_charge
                            )}
                          </span>
                        </td>
                      </tr>
                    )}
                    {parseFloat(
                      this.props?.myCartList?.order?.data?.attributes
                        ?.shipping_discount
                    ) > 0 && (
                      <tr>
                        <td>
                          <span
                            className="cart-product-amount-ttl"
                            style={{ fontSize: "14px" }}
                          >
                            {content.deliveryDiscount}
                          </span>
                        </td>
                        <td>
                          <span
                            className="cart-product-amount-price"
                            style={{ fontSize: "14px" }}
                          >
                            -
                            {showTwoDecimalDigit(
                              this.props?.myCartList?.order?.data?.attributes
                                ?.shipping_discount
                            )}
                          </span>
                        </td>
                      </tr>
                    )}
                  </>
                  {/* } */}
                </tbody>
              </Table>
              <div>
                <Table
                  className="mt-2 mb-0 cart-prodict-total-amount "
                  borderless
                >
                  <tbody>
                    {this.props?.myCartList?.order?.data?.attributes
                      ?.total_discount > 0 && (
                      <tr>
                        <td>
                          <span
                            className="cart-product-amount-ttl"
                            style={{ fontSize: "14px" }}
                          >
                            {content.discount}
                          </span>
                        </td>
                        <td>
                          <span
                            className="cart-product-amount-price"
                            style={{ fontSize: "14px" }}
                          >
                            -
                            {showTwoDecimalDigit(
                              this.props?.myCartList?.order?.data?.attributes
                                ?.total_discount
                            )}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <span className="homepage-cart-divider" />
              <div className="cart-coupon mt-3">
                {this.state.toggleRewards &&
                  this.props?.myCartList?.order?.data?.attributes
                    ?.coupon_code_id !== null && (
                    <>
                      <div className="my-cart-applied-code">
                        <span
                          data-testid="toggleCoupenModalID"
                          className="applied-coupon-code"
                          onClick={() => this.toggleCouponModal()}
                        >
                          {content.appliedCoupon}
                        </span>

                        <span
                          data-testid="toggleRewardsModalID"
                          className="applied-coupon-code"
                          onClick={() => this.setState({ toggleRewards: true })}
                        >
                          {
                            this.props?.myCartList?.order?.data?.attributes
                              ?.coupon?.attributes?.code
                          }
                          <BsX
                            data-testid="rmRewardDataID"
                            onClick={() =>
                              this.removeCoupon(
                                this.props?.myCartList?.order?.data
                              )
                            }
                            className="remove-item-svg"
                          />
                        </span>
                      </div>
                      <span className="homepage-cart-divider" />
                    </>
                  )}
                {this.state.toggleRewards &&
                  this.props?.myCartList?.order?.data?.attributes
                    ?.reward_history_id !== null && (
                    <>
                      <div className="my-cart-applied-code">
                        <span
                          className="applied-coupon-code"
                          data-testid="toggleCoupensModalID"
                          onClick={() => this.toggleCouponModal()}
                        >
                          {content.appliedReward}
                        </span>
                        <span
                          className="applied-coupon-code"
                          data-testid="toggleRewardsModalsID"
                          onClick={() => this.setState({ toggleRewards: true })}
                        >
                          {
                            this.props?.myCartList?.order?.data?.attributes
                              ?.used_reward_balance
                          }
                          <BsX
                            data-testid="rmRewardsDataID"
                            onClick={() => {
                              this.removeRewards(
                                this.props?.myCartList?.order?.data
                              );
                            }}
                          />
                        </span>
                      </div>
                      <span className="homepage-cart-divider" />
                    </>
                  )}
                <div className="my-cart-engagements">
                  <>
                    {!this.state.toggleRewards && (
                      <>
                        <span
                          data-testid="toggleCoupenModalID"
                          className="cart-coupon-code-message availableCoupons"
                          onClick={() => this.toggleCouponModal()}
                        >
                          {content.viewAvailableCoupons}
                        </span>

                        <span
                          data-testid="toggleRewardsModalID"
                          className="cart-coupon-code-message availableCoupons"
                          onClick={() => this.setState({ toggleRewards: true })}
                        >
                          {content.applyRewards}
                        </span>
                      </>
                    )}
                    {this.state.toggleRewards &&
                      this.props?.myCartList?.order?.data?.attributes
                        ?.coupon_code_id === null && (
                        <>
                          <span
                            className="cart-coupon-code-message availableCoupons"
                            data-testid="toggleCoupensID"
                            onClick={() => this.toggleCouponModal()}
                          >
                            {content.viewAvailableCoupons}
                          </span>

                          <span
                            className="cart-coupon-code-message availableCoupons"
                            data-testid="toggleRewardCoupensID"
                            onClick={() =>
                              this.setState({ toggleRewards: false })
                            }
                          >
                            {content.applyCoupons}
                          </span>
                        </>
                      )}
                  </>
                </div>
                {this.state.toggleRewards ? (
                  <Form className="yt-cart-disct-wrap pb-4">
                    <span className="reward-balance-span">
                      You have{" "}
                      <label>
                        <img src={rewardIcon} alt="" />
                        {
                          this.props?.myCartList?.order?.data?.attributes
                            ?.current_reward_balance
                        }{" "}
                        Reward points Left.
                      </label>{" "}
                      10 For every
                      <span>
                        {" "}
                        {String(
                          this.props?.myCartList?.order?.data?.attributes
                            ?.reward_setting?.exchange_rate * 10
                        )}
                      </span>
                    </span>
                    {/* } */}
                    <FormGroup
                      className="m-0 yt-form-cpn-err error"
                      /* yt-form-cpn-err success */
                    >
                      <input
                        type="number"
                        name="rewards"
                        data-testid="applyRewardsID"
                        className="form-control"
                        id="cart-total-products-amount"
                        placeholder="Apply Rewards"
                        value={this.state.rewardsQuantity || ""}
                        onChange={(e) => {
                          let limitValue =
                            this.props?.myCartList?.order?.data?.attributes
                              ?.reward_setting?.max_reward_usage_limit;
                          if (limitValue < e.target.value) {
                            errorNotification("Rewards Point Not Available");

                            this.setState({
                              rewardsQuantity: parseInt(e.target.value, 10),
                              codeErrorContent: "",
                            });
                          } else {
                            this.setState({
                              rewardsQuantity: parseInt(e.target.value, 10),
                              codeErrorContent: "",
                            });
                          }
                        }}
                        disabled={
                          !this.state.isRewardEnable &&
                          this.props?.myCartList?.order?.data?.attributes
                            ?.reward_history_id !== null
                        }
                        maxLength={15}
                      />
                      <div className="pb-3 d-flex align-items-center cart-coupon-bottom-wrapper justify-content-between">
                        <>
                          {this.props?.myCartList?.order?.data?.attributes
                            ?.reward_history_id &&
                            this.props?.myCartList?.order?.data?.attributes
                              ?.applied_reward_discount !== null &&
                            !this.state.rewardContent && (
                              <span
                                className="cart-coupon-code-message success-message"
                                style={{ color: "#6cb83a", display: "block" }}
                              >
                                You saved
                                {showTwoDecimalDigit(
                                  this.props?.myCartList?.order?.data
                                    ?.attributes?.applied_reward_discount
                                )}{" "}
                              </span>
                            )}
                          <span className="cart-coupon-code-message rewards-max-quantity">
                            Max Limit{" "}
                            {
                              this.props?.myCartList?.order?.data?.attributes
                                ?.reward_setting?.max_reward_usage_limit
                            }{" "}
                            Points at a time
                          </span>
                          {this.props?.myCartList?.order?.data?.attributes
                            ?.reward_history_id && (
                            <Button
                              color=" cart-coupon-change-btn p-0"
                              data-testid="removeRewardsID"
                              onClick={() =>
                                this.removeRewards(
                                  this.props?.myCartList?.order?.data
                                )
                              }
                            >
                              Remove Rewards
                            </Button>
                          )}
                        </>
                      </div>
                      {this.state.codeErrorContent && (
                        <span className="cart-coupon-code-message error-message">
                          {this.state.codeErrorContent}
                        </span>
                      )}
                      {parseInt(
                        this.props?.myCartList?.order?.data?.attributes
                          ?.applied_reward_discount,
                        10
                      ) > 0 && !this.state.isRewardEnable ? (
                        <Button
                          color=" cart-coupon-btn"
                          onClick={() => {
                            this.setState({
                              isRewardEnable: true,
                            });
                          }}
                        >
                          Change
                        </Button>
                      ) : (
                        <Button
                          data-testid="applyRewardsBtnID"
                          color=" cart-coupon-btn"
                          onClick={() =>
                            this.applyReward(
                              this.props?.myCartList?.order?.data
                            )
                          }
                        >
                          Apply
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                ) : (
                  <Form className="yt-cart-disct-wrap pb-4">
                    <FormGroup className="m-0 yt-form-cpn-err error">
                      <input
                        data-testid="applyCoupenCodeID"
                        name="copenCode"
                        type="text"
                        className="form-control"
                        id="cart-total-products-amount"
                        placeholder="Apply Coupon"
                        value={this.state.couponCode}
                        onChange={(e) =>
                          this.setState({
                            couponCode: e.target.value,
                            codeEmpty: false,
                            codeErrorContent: "",
                          })
                        }
                        disabled={
                          !this.state.isCouponEnable &&
                          this.props?.myCartList?.order?.data?.attributes
                            ?.coupon_code_id !== null
                        }
                        maxLength={15}
                      />
                      <div className="pb-3 d-flex align-items-center cart-coupon-bottom-wrapper justify-content-between">
                        {this.props?.myCartList?.order?.data?.attributes
                          ?.coupon_code_id !== null &&
                          !this.state.codeErrorContent && (
                            <>
                              <span
                                className="cart-coupon-code-message success-message"
                                style={{ color: "#6cb83a", display: "block" }}
                              >
                                You saved
                                {showTwoDecimalDigit(
                                  this.props?.myCartList?.order?.data
                                    ?.attributes?.applied_discount
                                )}
                              </span>
                              <Button
                                color=" cart-coupon-change-btn p-0"
                                data-testid="removeCoupenBtnID"
                                onClick={() =>
                                  this.removeCoupon(
                                    this.props?.myCartList?.order?.data
                                  )
                                }
                              >
                                Remove Coupon
                              </Button>
                            </>
                          )}

                        {this.state.codeEmpty && (
                          <span className="cart-coupon-code-message error-message">
                            {this.state.codeEmpty &&
                              "Coupon code can't be empty"}
                          </span>
                        )}
                      </div>
                      {!this.state.isCouponEnable &&
                      this.props?.myCartList?.order?.data?.attributes
                        ?.coupon_code_id !== null ? (
                        <Button
                          color=" cart-coupon-btn"
                          onClick={() =>
                            this.setState({
                              isCouponEnable: true,
                            })
                          }
                        >
                          Change
                        </Button>
                      ) : (
                        <Button
                          data-testid="applyCouponBtnID"
                          color=" cart-coupon-btn"
                          onClick={() =>
                            this.applyCoupon(
                              this.props?.myCartList?.order?.data
                            )
                          }
                        >
                          Apply
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                )}
              </div>
              <Table className="mb-0 cart-prodict-sub-total-amount " borderless>
                <tbody>
                  <tr>
                    <td>
                      <span
                        className="cart-product-amount-ttl color-dark "
                        style={{ fontWeight: "bold" }}
                      >
                        Total Amount
                      </span>
                    </td>
                    <td>
                      <span
                        className="cart-product-amount-price cart-sub-total color-dark"
                        style={{ fontWeight: "bold" }}
                      >
                        {showTwoDecimalDigit(
                          this.props?.myCartList?.order?.data?.attributes?.total
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="btn-proceed">
                <Button
                  data-testid="redirectBtnID"
                  className="cart-proceed py-3 px-5"
                  onClick={() => {
                    if (this.state.isLoggedIn) {
                      if (
                        getLocalState("commonSettings")?.min_cart_val &&
                        this.props?.myCartList?.order?.data?.attributes
                          ?.sub_total <
                          getLocalState("commonSettings")?.min_cart_val
                      ) {
                        errorNotification(
                          `Minimum Cart Value should be ${
                            getLocalState("commonSettings")?.min_cart_val
                          }`
                        );
                      } else {
                        this.props.history.push("/checkout");
                      }
                    } else {
                      this.props.history.push("/login");
                    }
                  }}
                >
                  Proceed to Checkout
                </Button>
              </div>
            </div>
            <div className="credit-div">
              <span className="my-span">
                You can earn{" "}
                <label>
                  <img src={rewardIcon} alt="" />
                  {
                    this.props?.myCartList?.order?.data?.attributes
                      ?.earn_reward_point
                  }{" "}
                  reward points
                </label>{" "}
                for completing your order!
              </span>
            </div>
          </div>
        ) : (
          <EmptyCart />
        )}
        {this.state.bannerImgList?.length > 0
          ? this.state.bannerImgList?.map((banner: any, index: any) => (
              <div className="mt-4 mb-40 text-center" key={index}>
                <img src={banner?.url} alt="" className="img-fluid adv-img" />
              </div>
            ))
          : new Array(2).fill(0).map((item: any, index: any) => (
              <div className="mt-4 h-25" key={index}>
                <AdvBannerSkeltonLoader />
              </div>
            ))}

        {/* Coupons Modal */}
        <Modal
          isOpen={this.state.showAllCoupons}
          toggle={() => this.toggleCouponModal()}
          className=""
          centered={true}
          modalClassName="popopop"
        >
          <ModalHeader
            className="coupon-title-bar  border-0"
            close={
              <img
                alt="close-btn"
                className="toggle-Modal"
                src={modalCloseIcon}
                onClick={() => this.toggleCouponModal()}
              />
            }
          >
            <span>{content.viewAvailableCoupons}</span>
          </ModalHeader>
          <ModalBody className="yt-coupon-wrap">
            {this.state.allCoupons.length > 0 ? (
              <>
                {this.state.allCoupons?.map((coupon, index) => (
                  <div
                    className="coupon-body-text"
                    onClick={() => this.copyCoupon(coupon, index)}
                    key={index}
                  >
                    <div className="row position-relative mb-3">
                      <div className="col-md-9 col-sm-9">
                        <p
                          className={`${
                            this.state.couponCopied === index ? "active" : null
                          }`}
                        >
                          {coupon?.attributes?.code}{" "}
                          {this.state.couponCopied === index
                            ? "Code Copied!"
                            : null}
                        </p>
                      </div>
                      <div className="col-md-3 col-sm-3 text-end">
                        <Button className="btn cart-coupon-btn">Apply</Button>
                      </div>
                    </div>
                    <span>
                      {/* @ts-ignore */}
                      Get{" "}
                      {coupon?.attributes?.discount_type === "flat"
                        ? `${showTwoDecimalDigit(coupon?.attributes?.discount)}`
                        : `${showTwoDecimalDigit(
                            coupon?.attributes?.discount
                          )}%`}{" "}
                      <span>Off on order of minimum </span>
                      {showTwoDecimalDigit(
                        coupon?.attributes?.min_cart_value
                      )}{" "}
                      <span>to </span>
                      {showTwoDecimalDigit(coupon?.attributes?.max_cart_value)}.
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <div className="coupon-body-text noCoupon">
                <p>{content.noCouponsAvailable}</p>
              </div>
            )}
          </ModalBody>
        </Modal>

        {/* Modal for Delete a Product From Cart Start */}
        {this.state.isCartItemRemove && (
          <ConfirmActionModel
            closeConfirmActionModel={this.removeProductModalClose}
            onHandleBtnAction={this.removeProductFromCart}
            isOpenConfirmActionModel={this.state.isCartItemRemove}
            modelHeaderText={content.removeProduct}
            modelBodyText={content.removeProductTagline}
            btnActionText={content.yesDelete}
            btnCancelText={content.cancelBtn}
          />
        )}
        {/* Modal for Delete a Product From Cart end */}
      </>
    );
    // Customizable Area End
  }
}

export default withRouter(ShoppingCart);
export { ShoppingCart };
// Customizable Area Start
// Customizable Area End
