import React, { RefObject } from "react";
import { RouteComponentProps } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  getLocalState,
  setLocalState,
  showTwoDecimalDigit,
  successNotification,
} from "../../studio-store-restaurant-components/src/Utility.web";
import { FormikProps } from "formik";
export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  myCategory: Array<any>;
  getProducts: () => void;
  getCarts: () => void;
  myLoader: boolean;
  myProductsList: any;
  updateProductsQuantity: (val: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean;
  isVeg: boolean;
  itemQuantity: number;
  showAddToCart: boolean;
  cartProduct: any;
  cartTotal: number | string;
  cartIds: Array<any>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class FilterItemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postWishlistApiCallId: string = "";
  removeWishlistApiCallId: string = "";
  addToCartApiCallId: string = "";
  updateCartApiCallId: string = "";

  formRef: RefObject<FormikProps<{}>>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.UpdateWishlist),
      getName(MessageEnum.UpdateCartListApiMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      loader: false,
      showAddToCart: false,
      isVeg: false,
      cartProduct: [],

      cartTotal: 0,
      itemQuantity: 0,
      cartIds: [],
      // Customizable Area End
    };
    this.formRef = React.createRef();
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("MY Message Received", message);
    if (message.id === getName(MessageEnum.RestAPIResponseMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponseDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponseSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponseErrorMessage)
      );
      if (responseJSON && responseJSON.data) {
        if (apiRequestCallID !== null) {
          this.setState({ itemQuantity: 0 });
          //create wishlist
          if (apiRequestCallID === this.postWishlistApiCallId) {
            successNotification(responseJSON?.message);
            const wishlist_length = parseInt(
              getLocalState("wishlist_len") ?? "0"
            );
            setLocalState("wishlist_len", wishlist_length + 1);
            let wishlistUpdateMessage = new Message(
              getName(MessageEnum.UpdateWishlist)
            );
            wishlistUpdateMessage.addData(
              getName(MessageEnum.UpdateWishlistLen),
              wishlist_length + 1
            );
            runEngine.sendMessage(
              wishlistUpdateMessage.id,
              wishlistUpdateMessage
            );
            this.props?.getProducts();
          }
          //delete wishlist
          if (apiRequestCallID === this.removeWishlistApiCallId) {
            successNotification(responseJSON?.message);
            const wishlist_length = parseInt(
              getLocalState("wishlist_len") ?? "0"
            );
            setLocalState("wishlist_len", wishlist_length - 1);
            let wishlistUpdateMessage = new Message(
              getName(MessageEnum.UpdateWishlist)
            );
            wishlistUpdateMessage.addData(
              getName(MessageEnum.UpdateWishlistLen),
              wishlist_length - 1
            );
            runEngine.sendMessage(
              wishlistUpdateMessage.id,
              wishlistUpdateMessage
            );
            this.props?.getProducts();
          }
          //add to cart
          if (apiRequestCallID === this.addToCartApiCallId) {
            successNotification("Product has been Added To Cart");

            const cartDataLength = parseInt(
              getLocalState("cartDataLen") ?? "0"
            );

            setLocalState("cartDataLen", cartDataLength + 1);
            let cartListUpdateMessage = new Message(
              getName(MessageEnum.UpdateCartListApiMessage)
            );
            cartListUpdateMessage.addData(
              getName(MessageEnum.UpdateCartListApiResponseMessage),
              cartDataLength + 1
            );
            runEngine.sendMessage(
              cartListUpdateMessage.id,
              cartListUpdateMessage
            );
            this.props?.getProducts();
            this.props?.getCarts();
          }
          //update cart
          if (apiRequestCallID === this.updateCartApiCallId) {
            successNotification("Cart has been Updated.");
            this.props?.getProducts();
            this.props?.getCarts();
          }
        }
      }
      if (errorMessage) {
        this.setState({ loader: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  //Add To Wishlist
  addToWishlist = async (catalogue_id: number) => {
    const httpBody = {
      catalogue_id: catalogue_id,
    };

    this.postWishlistApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.postWishlistAPIEndpoint,
      body: httpBody,
    });
  };

  //Remove from Wishlist
  removeWishlist = async (catalogue_id: number) => {
    this.removeWishlistApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiDeleteMethod,
      endPoint: `${configJSON.delWishlistAPIEndpoint}${catalogue_id}`,
    });
  };

  //Toggle Add To Cart modal
  toggleAddToCartModal = (catalogue: any): void => {
    this.setState({
      showAddToCart: !this.state.showAddToCart,
      cartProduct: catalogue,
      cartTotal: 0,
      cartIds: [],
    });
  };

  //Calculate Cart Total & populate subs ids
  calculateCartTotal = (catalogue: any): void => {
    let productPrice = this.state.cartProduct?.attributes?.on_sale
      ? this.state.cartProduct?.attributes?.sale_price
      : this.state.cartProduct?.attributes?.price;
    let sampleArray = [];
    let totalSum = productPrice;
    let ids: Array<any> = [];
    for (let key in catalogue) {
      if (catalogue.hasOwnProperty(key)) {
        const val = catalogue[key];
        sampleArray.push(val);
      }
    }
    sampleArray.forEach((content) => {
      if (typeof content === "string") {
        const [sum, id] = content.split(",");
        totalSum = parseFloat(totalSum) + parseFloat(sum);
        ids.push({ catalogue_attribute_value_id: id });
      } else if (typeof content === "object") {
        content.forEach((myData: string) => {
          const [sum, id] = myData.split(",");
          totalSum = parseFloat(totalSum) + parseFloat(sum);
          ids.push({ catalogue_attribute_value_id: id });
        });
      }
    });
    this.setState({
      cartTotal: showTwoDecimalDigit(totalSum),
      cartIds: ids,
    });
  };

  //Add To Cart
  addToCart = async (type: any) => {
    this.setState({ showAddToCart: false });

    let httpBody: {
      catalogue_id: number;
      quantity: number;
      order_item_options_attributes?: Array<any>;
    };
    if (type === "Modal") {
      httpBody = {
        catalogue_id: parseInt(this.state.cartProduct?.id),
        quantity: 1,
        order_item_options_attributes: this.state.cartIds,
      };
    } else {
      httpBody = {
        catalogue_id: parseInt(type?.id),
        quantity: 1,
      };
    }

    this.addToCartApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.orderBlockAPIEndpoint,
      body: httpBody,
    });
  };

  //Update Cart
  updateCartQuantity = (product: any, action: string): void => {
    if (action === "Add") {
      this.setState({
        itemQuantity:
          product?.attributes?.cart_quantity + this.state.itemQuantity + 1,
      });
    } else if (action === "Subtract") {
      this.setState({
        itemQuantity:
          product?.attributes?.cart_quantity + this.state.itemQuantity - 1,
      });
    }

    setTimeout(async () => {
      const httpBody = {
        catalogue_id: parseInt(product?.id),
        quantity: this.state.itemQuantity,
      };

      this.updateCartApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: configJSON.orderBlockAPIEndpoint,
        body: httpBody,
      });
    }, 200);
  };

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
