import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
/* START -------------------------------------------------------------- Set Storage Data */
export const setLocalState = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (err) {
    // Ignore write errors.
  }
};

export const getLocalState = (key: string) => {
  try {
    const serializedState =
      key === "token"
        ? window.localStorage.getItem(key)
        : JSON.parse(window.localStorage.getItem(key)!);
    if (serializedState === null) {
      return null;
    }
    return serializedState;
  } catch (err) {
    return null;
  }
};

export const removeLocalState = (key: string) => {
  try {
    const serializedState = window.localStorage.removeItem(key);
    return serializedState;
  } catch (err) {
    return null;
  }
};

/* END -------------------------------------------------------------- Set Storage Data */

/* START -------------------------------------------------------------- Showing two digit decimal number */
export const showTwoDecimalDigit = (number: string | number) => {
  const currency = getLocalState("country_info")?.currency_type;
  return `${currency} ${Number(number ?? 0).toFixed(2)}`;
};
/* END -------------------------------------------------------------- Showing two digit decimal number */

/* START -------------------------------------------------------------- Create common api interceptor */
interface ApiProps {
  contentType: string;
  method: string;
  endPoint: string;
  body?: any;
}

export const apiCall = async (apiData: ApiProps) => {
  const { contentType, method, endPoint, body } = apiData;
  const token = await getLocalState("token");
  const header = {
    "Content-Type": contentType,
    token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponseEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
/* END -------------------------------------------------------------- Create common api interceptor */

/* START -------------------------------------------------------------- Toast Messages */
export const successNotification = (msg: string) => toast.success(msg);

export const errorNotification = (msg: string) => toast.success(msg);

export const infoNotification = (msg: string) => toast.info(msg);

export const warnNotification = (msg: string) => toast.warn(msg);
/* END -------------------------------------------------------------- Toast Messages */
