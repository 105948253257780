import React from "react";
import { withRouter } from "react-router-dom";
import FilterOptionListController from "./FilterOptionsListController.web";
import "../assets/css/filterOptions.css";

// Customizable Area Start
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Button } from "reactstrap";
// Customizable Area End

class FilterOptions extends FilterOptionListController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    let itemsCategory = this.props.myCategory;
    if (this.state.buttonsToAllow) {
      itemsCategory = this.props.myCategory?.slice(
        0,
        this.state.buttonsToAllow
      );
    }
    let itemsMoreCategory = [];
    if (this.state.shouldAddMore)
      itemsMoreCategory = this.props.myCategory?.slice(
        this.state.buttonsToAllow,
        this.props.myCategory?.length
      );

    return (
      <div className="text-center mt-5 mb-5  m-auto" ref={this.wrapperRef}>
        <div className="d-inline-flex pills-button d-inline-flex justify-content-center">
          <div className="filter-option-inner-contnet">
            <div className="p-0 m-0 list-style-none profile-pg-tabs-name d-flex justify-content-center align-items-center">
              {itemsCategory &&
                itemsCategory?.map((category: any, index: any) => (
                  <Button
                    ref={this.buttonRef}
                    key={category?.id}
                    onClick={() => this.routeToFilter(category?.id, index)}
                    className={
                      this.state.activeCategoryId === category?.id
                        ? "category-pills-btn active"
                        : "category-pills-btn"
                    }
                  >
                    {category.attributes.name}
                  </Button>
                ))}
            </div>
          </div>
          {this.state.shouldAddMore && (
            <>
              <div
                className="more-fill-pills"
                onClick={() => this.setState({ more: !this.state.more })}
              >
                <span data-testid="moreItemListID">
                  More &nbsp;
                  {this.state.more ? (
                    <FaCaretUp
                      color="#3fc1cb"
                      style={{ height: "1.4rem", width: "1.4rem" }}
                    />
                  ) : (
                    <FaCaretDown
                      style={{ height: "1.4rem", width: "1.4rem" }}
                    />
                  )}
                </span>
              </div>
              <div className="position-relative">
                <div
                  className={
                    this.state.more ? "more-btn-inline" : "more-btn-none"
                  }
                >
                  <div style={{ display: "grid" }}>
                    {itemsMoreCategory &&
                      itemsMoreCategory?.map((category: any, index: any) => (
                        <>
                          <Button
                            key={category?.id}
                            onClick={() =>
                              this.routeToFilter(category?.id, index)
                            }
                            className={
                              this.state.activeCategoryId === category?.id
                                ? "category-pills-btn m-2 active"
                                : "category-pills-btn m-2"
                            }
                          >
                            {category.attributes.name}
                          </Button>
                          <span className="border-bottom"></span>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  // Customizable Area End
}

export default withRouter(FilterOptions);
export { FilterOptions };
// Customizable Area Start
// Customizable Area End
