import { RouteComponentProps } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  errorNotification,
  getLocalState,
  setLocalState,
  successNotification,
} from "../../studio-store-restaurant-components/src/Utility.web";
import { createRef, RefObject } from "react";
import { FormikProps } from "formik";
export const configJSON = require("./config");
// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  myCartList: any;
  getProducts: () => void;
  getCarts: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bannerImgList: any;
  allCoupons: Array<any>;
  showAllCoupons?: boolean;
  loader: boolean;
  couponCopied: string | number;
  itemQuantity: any;
  couponCode: string;
  rewardsQuantity: number;
  codeEmpty: boolean;
  rewardIsEmpty: boolean;
  codeErrorContent: string;
  customizedData: any;
  toggleRewards: boolean;
  //for remove cartItem states
  isCartItemRemove: boolean;
  cartItemId: string;
  rewardContent: string;
  isRewardEnable: boolean;
  isCouponEnable: boolean;
  isLoggedIn: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCartAPICallId: string = "";
  getAllCouponsAPICallId: string = "";
  updateCartApiCallId: string = "";
  removeCartItemsApiCallId: string = "";
  applyCouponApiCallId: string = "";
  removeCouponApiCallId: string = "";
  applyRewardsApiCallId: string = "";
  removeRewardsApiCallId: string = "";
  formRef: RefObject<FormikProps<{}>>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.BannerListApiMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      bannerImgList: [],
      allCoupons: [],
      loader: false,
      showAllCoupons: false,
      couponCopied: "",
      rewardsQuantity:
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance &&
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance,
      itemQuantity: 0,
      couponCode:
        this.props?.myCartList?.order?.data?.attributes?.coupon &&
        this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes
          ?.code,
      codeErrorContent: "",
      codeEmpty: false,
      rewardIsEmpty: false,
      customizedData: "",
      toggleRewards: false,
      isRewardEnable:
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance &&
        false,
      isCouponEnable:
        this.props?.myCartList?.order?.data?.attributes?.coupon_code_id &&
        false,
      isLoggedIn: false,
      isCartItemRemove: false,
      cartItemId: "",
      rewardContent: "",
      // Customizable Area End
    };

    this.formRef = createRef();
  }

  // Customizable Area Start
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps) {
      this.setState({
        couponCode:
          nextProps?.myCartList?.order?.data?.attributes?.coupon &&
          nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes
            ?.code
            ? nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes
                ?.code
            : "",
        isCouponEnable:
          nextProps?.myCartList?.order?.data?.attributes?.coupon_code_id &&
          false,
        rewardsQuantity:
          nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance &&
          nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance,
        isRewardEnable:
          nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance &&
          false,
      });
      const userData = getLocalState("userData");
      const userCompletedInfo = getLocalState("userCompleteInfo");
      const registartionNotCompleted = getLocalState("registrationDetails");
      const isGuest = getLocalState("guestUserID");
      if (userData && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (isGuest) {
        this.setState({
          isLoggedIn: false,
        });
      }
      if (isGuest && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (userData && registartionNotCompleted) {
        this.setState({
          isLoggedIn: false,
        });
      }
    } else {
      this.setState({
        couponCode:
          this.props?.myCartList?.order?.data?.attributes?.coupon &&
          this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes
            ?.code
            ? nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes
                ?.code
            : "",
        isCouponEnable:
          this.props?.myCartList?.order?.data?.attributes?.coupon_code_id &&
          false,
        rewardsQuantity:
          this.props?.myCartList?.order?.data?.attributes
            ?.used_reward_balance &&
          this.props?.myCartList?.order?.data?.attributes?.used_reward_balance,
        isRewardEnable:
          this.props?.myCartList?.order?.data?.attributes
            ?.used_reward_balance && false,
      });
    }
  }
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    const userData = getLocalState("userData");
    if (userData) {
      this.setState({
        isLoggedIn: true,
      });
    }
    this.setState({
      rewardsQuantity:
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance &&
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance,
      isRewardEnable:
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance &&
        true,
      couponCode:
        this.props?.myCartList?.order?.data?.attributes?.coupon &&
        this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes
          ?.code
          ? this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes
              ?.code
          : null,
      isCouponEnable:
        this.props?.myCartList?.order?.data?.attributes?.coupon_code_id &&
        false,
    });
    this.getCoupons();
    // Customizable Area End
  }

  // Customizable Area Start
  removeProductModalClose = () => {
    this.setState({
      isCartItemRemove: !this.state.isCartItemRemove,
    });
  };
  findOrderItemOptions = (
    itemName: string,
    data: { attributes: { order_item_options: any[] } }
  ) => {
    return data?.attributes?.order_item_options?.find(
      (ele: { attributes: { name: string } }) =>
        ele?.attributes?.name === itemName
    )
      ? true
      : false;
  };
  handleCustomize = (data: any) => {
    data?.attributes?.catalogue?.attributes?.catalogue_catalogue_attributes.map(
      (ele: {
        attributes: { catalogue_catalogue_attribute_values: any[] };
      }) => {
        ele?.attributes?.catalogue_catalogue_attribute_values?.map(
          (ele2: { isSelected: boolean; attributes: { name: string } }) => {
            ele2.isSelected = this.findOrderItemOptions(
              ele2?.attributes?.name,
              data
            );
          }
        );
      }
    );
    this.setState({
      customizedData: data,
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("MY Message Received", message);
    this.setState({ loader: false });
    if (message.id === getName(MessageEnum.BannerListApiMessage)) {
      const bannerListData = message.getData(
        getName(MessageEnum.BannerListApiResponseMessage)
      );
      if (bannerListData) {
        this.bannerImgListResponce(bannerListData);
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponseMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponseDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponseSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponseErrorMessage)
      );

      if (responseJSON && responseJSON.data) {
        if (apiRequestCallID !== null) {
          //get all coupons
          if (apiRequestCallID === this.getAllCouponsAPICallId) {
            this.setState({
              allCoupons: responseJSON.data,
            });
          }

          //update cart
          if (apiRequestCallID === this.updateCartApiCallId) {
            this.setState({ itemQuantity: 0, isCartItemRemove: false });
            successNotification("Cart has been Updated.");
            this.props?.getProducts();
            this.props?.getCarts();
          }

          if (apiRequestCallID === this.removeCartItemsApiCallId) {
            this.setState({ itemQuantity: 0, isCartItemRemove: false });
            successNotification("Items Remove from cart.");
            const cartDataLength = parseInt(
              getLocalState("cartDataLen") ?? "0"
            );
            setLocalState("cartDataLen", cartDataLength - 1);
            let cartListUpdateMessage = new Message(
              getName(MessageEnum.UpdateCartListApiMessage)
            );
            cartListUpdateMessage.addData(
              getName(MessageEnum.UpdateCartListApiResponseMessage),
              cartDataLength - 1
            );
            runEngine.sendMessage(
              cartListUpdateMessage.id,
              cartListUpdateMessage
            );
            this.props?.getProducts();
            this.props?.getCarts();
          }

          //coupon apply
          if (apiRequestCallID === this.applyCouponApiCallId) {
            this.setState({ itemQuantity: 0, loader: false });
            successNotification("Coupon successfully applied.");
            this.props?.getCarts();
          }

          //remove coupon
          if (apiRequestCallID === this.removeCouponApiCallId) {
            this.setState({
              loader: false,
              toggleRewards: false,
              couponCode: "",
            });

            successNotification("Coupon removed successfully.");
            this.props?.getCarts();
          }

          //apply rewards
          if (apiRequestCallID === this.applyRewardsApiCallId) {
            this.setState({
              itemQuantity: 0,
              loader: false,
              rewardContent: "",
              isRewardEnable: false,
            });
            successNotification("Rewards successfully applied.");
            this.props?.getCarts();
          }

          //remove rewards
          if (apiRequestCallID === this.removeRewardsApiCallId) {
            this.setState({
              loader: false,
              toggleRewards: false,
              couponCode: "",
              rewardContent: "",
            });

            successNotification("Rewards Removed Successfully.");
            this.props?.getCarts();
          }
        }
      }
      if (
        responseJSON &&
        responseJSON.errors &&
        responseJSON.errors.length > 0
      ) {
        if (apiRequestCallID !== null) {
          if (apiRequestCallID === this.applyRewardsApiCallId) {
            this.setState({
              codeErrorContent: responseJSON.errors[0]?.message,
              isRewardEnable: true,
              loader: false,
            });
          }
          if (apiRequestCallID === this.applyCouponApiCallId) {
            this.setState({
              loader: false,
            });
            errorNotification(responseJSON.errors[0].message);
          }
        }
      }
      if (errorMessage) {
        this.setState({ loader: false });
        // window.notify([
        //     { type: "danger", message: errorMessage || content.someThingWent },
        // ]);
      }
    }
    // Customizable Area End
  }

  /* START -------------------------------------------------------------- Banner Img List */
  bannerImgListResponce = (apiResData: any) => {
    const bannerData = apiResData?.filter(
      (banner: { attributes: { banner_position: number } }) =>
        banner.attributes?.banner_position === 2 ||
        banner.attributes?.banner_position === 3
    );
    const bannerDataImg = bannerData?.map(
      (banner: { attributes: { images: any } }) => banner.attributes?.images
    );
    let bannerList = [];
    for (let i = 0; i < bannerDataImg?.length; i++) {
      bannerList.push(bannerDataImg[i]?.data[0]?.attributes);
    }
    this.setState({ bannerImgList: bannerList });
  };
  /* END -------------------------------------------------------------- Banner Img List */

  //toggle View coupons Modal
  toggleCouponModal = () => {
    this.setState({
      showAllCoupons: !this.state.showAllCoupons,
    });
  };

  //get all coupons
  getCoupons = async () => {
    this.getAllCouponsAPICallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCouponsListAPIEndPoint,
    });
  };

  //copy coupon
  copyCoupon = (coupon: any, index: any) => {
    this.setState({
      couponCopied: index,
      couponCode: coupon?.attributes?.code,
      codeEmpty: false,
      codeErrorContent: "",
      showAllCoupons: false,
    });
    setTimeout(() => {
      this.applyCoupon(this.props?.myCartList?.order?.data);
    }, 1000);
  };

  //updateCart
  updateCartQuantity = (product: any, action: string) => {
    if (action === "Add") {
      this.setState({
        itemQuantity:
          product?.attributes?.quantity + this.state.itemQuantity + 1,
      });
    } else if (action === "Subtract") {
      this.setState({
        itemQuantity:
          product?.attributes?.quantity + this.state.itemQuantity - 1,
      });
    }

    setTimeout(async () => {
      let subOrderId = product?.attributes?.order_item_options.map(
        (item: {
          attributes: { catalogue_attribute_value_id: any; id: any };
        }) => {
          return {
            catalogue_attribute_value_id:
              item?.attributes?.catalogue_attribute_value_id,
            id: item?.attributes?.id,
          };
        }
      );
      let httpBody;
      if (subOrderId?.length > 0) {
        httpBody = {
          order_item_id: parseInt(product?.id, 10),
          order_item_options_attributes: subOrderId,
          quantity: this.state.itemQuantity,
        };
      } else {
        httpBody = {
          order_item_id: parseInt(product?.id, 10),
          quantity: this.state.itemQuantity,
        };
      }

      this.updateCartApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: configJSON.orderBlockAPIEndpoint,
        body: httpBody,
      });
    }, 1000);
  };

  //apply Coupon
  applyCoupon = async (cartData: {
    attributes: { sub_total: string };
    id: string | number;
  }) => {
    this.setState({ codeEmpty: false, codeErrorContent: "", loader: true });
    if (this.state.couponCode.length > 0) {
      const httpBody = {
        code: this.state.couponCode,
        cart_value: parseFloat(cartData?.attributes?.sub_total),
      };

      this.applyCouponApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: `${configJSON.orderBlockAPIEndpoint}/${cartData?.id}/apply_coupon`,
        body: httpBody,
      });
    } else {
      this.setState({ codeEmpty: true, loader: false });
    }
  };

  //apply rewards
  applyReward = async (cartData: any) => {
    let httpBody;
    if (this.state.rewardsQuantity === null) {
      this.setState({ codeErrorContent: `Rewards can't be empty` });
    } else if (
      this.state.rewardsQuantity <=
        this.props?.myCartList?.order?.data?.attributes?.reward_setting
          ?.max_reward_usage_limit ||
      this.state.rewardsQuantity === null
    ) {
      this.setState({ loader: true, isRewardEnable: false });

      if (
        this.props?.myCartList?.order?.data?.attributes?.reward_history_id !==
        null
      ) {
        httpBody = {
          cart_value: parseFloat(cartData?.attributes?.sub_total),
          cart_id: parseInt(cartData?.id, 10),
          used_reward: this.state.rewardsQuantity,
          reward_history_id: parseInt(
            cartData?.attributes?.reward_history_id,
            10
          ),
        };
      } else {
        httpBody = {
          cart_value: parseFloat(cartData?.attributes?.sub_total),
          cart_id: parseInt(cartData?.id, 10),
          used_reward: this.state.rewardsQuantity,
        };
      }

      this.applyRewardsApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: configJSON.applyRewardsAPIEndPoint,
        body: httpBody,
      });
    } else if (
      this.state.couponCode?.length > 0 ||
      this.state.couponCopied <=
        this.props?.myCartList?.order?.data?.attributes?.reward_setting
          ?.max_reward_usage_limit
    ) {
      this.setState({
        codeErrorContent: "Enter Rewards points under Limit bar",
      });
    }
  };

  //remove Coupon
  removeCoupon = async (cartData: { id: string | number }) => {
    this.setState({ loader: true });

    this.removeCouponApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiDeleteMethod,
      endPoint: `${configJSON.orderBlockAPIEndpoint}/${cartData?.id}`,
    });
  };

  //remove from cart
  removeProductFromCart = async () => {
    this.setState({});

    const httpBody = {
      order_item_id: this.state.cartItemId,
      quantity: 0,
    };

    this.removeCartItemsApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.orderBlockAPIEndpoint,
      body: httpBody,
    });
  };

  //remove applied rewards
  removeRewards = async (cartData: { id: string | number }) => {
    this.setState({ loader: true });

    this.removeRewardsApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiDeleteMethod,
      endPoint: `${configJSON.applyRewardsAPIEndPoint}/${cartData?.id}`,
    });
  };

  onHandleCustomizeItems = (value: any) => {
    let itemCustomizeString = value?.map(
      (item: { attributes: { name: any } }) => item?.attributes?.name
    );
    return itemCustomizeString?.join(", ");
  };
}
